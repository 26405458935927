import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Card Emphasis",
  "type": "Content"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Card Emphasis`}</strong>{` component should be used to style a section inside a card that is
important or should look different. The `}<strong parentName="p">{`Card Emphasis`}</strong>{` should only be used inside a
`}<a parentName="p" {...{
        "href": "/card"
      }}><strong parentName="a">{`Card`}</strong></a>{` component.`}</p>
    <hr></hr>
    <h2>{`Example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true background=layout",
        "live": "true",
        "background": "layout"
      }}>{`<div style={{ width: 400, margin: "auto" }}>
  <Card>
    Content of a card
    <Separator />
    <CardEmphasis>
      Important information
    </CardEmphasis>
  </Card>
</div>
`}</code></pre>
    <h2>{`Stretch emphasis`}</h2>
    <p>{`A `}<strong parentName="p">{`Card Emphasis`}</strong>{` can cover the whole card content horizontally when the prop
`}<inlineCode parentName="p">{`stretched`}</inlineCode>{` is used.`}</p>
    <blockquote>
      <p parentName="blockquote">{`When the `}<strong parentName="p">{`Card Emphasis`}</strong>{` is the last element in a card it will render with a border
radius of 4px, so it matches the `}<strong parentName="p">{`Card`}</strong>{` component's border.`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true background=layout",
        "live": "true",
        "background": "layout"
      }}>{`<div style={{ width: 400, margin: "auto" }}>
  <Card style={{ paddingBottom: 0 }}>
    <Heading level="1">Content of a card</Heading>
    <div style={{ height: "20px" }}/>
    <CardEmphasis stretch>
      Important information
    </CardEmphasis>
    <div style={{ height: "20px" }}/>
    <Paragraph>More text</Paragraph>
    <div style={{ height: "20px" }}/>
    <CardEmphasis stretch>
      Important information
    </CardEmphasis>
  </Card>
</div>
`}</code></pre>
    <h2>{`Props`}</h2>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Card Emphasis`}</strong>{` component passes all its props to the underlying `}<em parentName="p">{`div`}</em>{` element.`}</p>
    </blockquote>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`stretch`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Stretch the emphasis to the border of the card`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      